import './css/App.css';
import React from 'react';
import PureMarkdown from './VCC/Utilities/PureMarkdown';


const content=`
# Welcome to the Victorian Coding Challenge Quiz Competition!

Are you ready to test your coding skills and compete with the best? The Victorian Coding Challenge is here to provide you with an exciting opportunity to showcase your programming prowess!

## About the Competition

The Victorian Coding Challenge is a thrilling quiz competition designed for coders of all levels. You'll face a series of challenging questions that cover various programming languages, algorithms, data structures, and problem-solving techniques.

## How to Participate

1. **[Login to Start the Quiz](/VCC/Login)**: Click the link to login to your account. If you don't have an account yet, you'll have the ask the teacher to create one.
2. **Access the Quiz**: Once logged in, begin giving your quiz answers.
3. **Complete the Quiz**: Answer the questions to the best of your ability within the allotted time.

## Competition Rules

- The quiz is time-limited, so be prepared before you start.
- No external assistance is allowed; rely on your own knowledge and skills.
- Make sure to submit your answers before the time runs out.

---

**Ready to take on the challenge?**  
👉 **[Click here to login and start the quiz!](/VCC/Login)** 👈

---

Good luck, and happy coding!

---
`

function HomePage() {
  return (
    <PureMarkdown content={content}/>

  );
}

export default HomePage;