import React from 'react';
import '../css/Navbar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';

function Navbar2023() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const adminRoutes = ['/VCC/Admin','/VCC/ManualScore','/VCC/FinalRankings','/VCC/UpdateQuestions'];

  const isAdminRoute = adminRoutes.includes(location.pathname);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/VCC/AdminLogin');
  };

  const handleStudentLogout = () => {
    dispatch(logout());
    navigate('/VCC/Login');
  };

    return (
      <nav className="navbar">
        {isAdminRoute ? (
          <>
        <Link to="/VCC/Admin">Home</Link>
        <Link to="/VCC/ManualScore">Manual Score</Link>
        <Link to="/VCC/FinalRankings">Final Rankings</Link>
        <Link to="/VCC/UpdateQuestions">Update Questions</Link>
        <button  onClick={handleLogout} className="logout-button" >Logout</button>
        </>
        ) : (
          <>
        <Link to="/">Home</Link>
        <Link to="/VCC/VCCHome"> VCC Home</Link>
        <Link to="/VCC/Rankings">Rankings</Link>
        <Link to="/VCC/Tutorial">API Tutorial</Link>
        <Link to="/VCC/AgentTutorial">Agent Tutorial</Link>
        {currentUser.role === "student" && <button  onClick={handleStudentLogout} className="logout-button" >Logout</button> }
        </>
        )}
  </nav>
    );
  }

export default Navbar2023;