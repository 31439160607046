import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/rankings.css';

const FinalRankings = () => {
  const API_URL = useSelector((state) => state.auth.ApiUrl);
  const AGENT_API_URL = useSelector((state) => state.auth.AgentApiUrl);
  const [teams, setTeams] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState('');
  const [leagues, setLeagues] = useState([]);
  const [publishedResults, setPublishedResults] = useState([]);
  const [multiplier, setMultiplier] = useState(0.5);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    // Fetch the list of leagues and published results once
    const fetchPublishedResults = async () => {
      try {
        const response = await fetch(`${AGENT_API_URL}/get_published_results_for_all_leagues`, {
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        });
        const data = await response.json();
        const allResults = data.data.all_results;
        if (allResults && allResults.length > 0) {
          setPublishedResults(allResults);
          const leagueNames = allResults.map(result => result.league_name);
          setLeagues(leagueNames);
          setSelectedLeague(leagueNames[0]); // Default to the first league
        }
      } catch (error) {
        console.error("Error fetching published results:", error);
      }
    };

    fetchPublishedResults();
  }, []);


  useEffect(() => {
    const fetchAndCombineData = async () => {
      if (!selectedLeague) {
        return;
      }

    try {
      const response = await fetch(`${API_URL}/get_comp_table`, {
        method: "GET",
        headers: {
          "Accept": "application/json"
        }
      });

      const data1 = await response.json();


      const teamMap = {};


      data1.data.forEach(team => {
        teamMap[team.name] = {
          name: team.name,
          mainCompScore: team.score || 0,
          color: team.color || 'white',
        };
      });


      const selectedResult = publishedResults.find(result => result.league_name === selectedLeague);

      if (selectedResult) {
        const totalPoints = selectedResult.total_points; 

        if (totalPoints) {
          Object.entries(totalPoints).forEach(([teamName, agentScore]) => {
            if (teamMap[teamName]) {
              // Team exists in the first dataset, merge data
              teamMap[teamName].agentScore = agentScore; // 'Agent Score'
            } else {
              
              teamMap[teamName] = {
                name: teamName,
                mainCompScore: 0,
                agentScore: agentScore,
                color: 'white',
              };
            }

            // Calculate 'Total Points' using the formula
            const totalPointsValue = (agentScore * multiplier) + teamMap[teamName].mainCompScore;
            teamMap[teamName].totalPoints = totalPointsValue;
          });
        }

        // For teams that only exist in data1
        Object.values(teamMap).forEach(team => {
          if (team.agentScore === undefined) {
            team.agentScore = 0;
            team.totalPoints = (team.agentScore * multiplier) + team.mainCompScore;
          }
        });
      } else {
        console.error(`No results found for league "${selectedLeague}".`);
        // Set agentScore to 0 for all teams
        Object.values(teamMap).forEach(team => {
          team.agentScore = 0;
          team.totalPoints = (team.agentScore * multiplier) + team.mainCompScore;
        });
      }

      // Convert teamMap to an array
      const mergedTeams = Object.values(teamMap);

      // Sort teams based on totalPoints
      const sortedTeams = mergedTeams.sort((a, b) => (b.totalPoints || 0) - (a.totalPoints || 0));

      // Set state
      setTeams(sortedTeams);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  fetchAndCombineData();

  const intervalId = setInterval(() => {
    fetchAndCombineData();
  }, 5000);

  return () => clearInterval(intervalId); // Cleanup interval on component unmount
}, [selectedLeague, multiplier, publishedResults]);

const handleLeagueChange = (e) => {
  setSelectedLeague(e.target.value);
};

const handleMultiplierChange = (e) => {
  let value = parseFloat(e.target.value);
  if (isNaN(value) || value < 0) {
    value = 0;
  }
  setMultiplier(value);
};

return (
  <section className="main-container">
    <div className="controls">
      <div className="control-group">
        <label htmlFor="league-select" className="control-label">
          <span className="label-text">Select League:</span>
          <select
            id="league-select"
            value={selectedLeague}
            onChange={handleLeagueChange}
            className="league-select"
          >
            {leagues.map((league, index) => (
              <option key={index} value={league}>
                {league}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="control-group">
      <label htmlFor="multiplier-input" className="control-label">
        <span className="multiplier-label">Multiplier:</span>
        <input
      id="multiplier-input"
      type="number"
      value={multiplier}
      onChange={handleMultiplierChange}
      step="0.1"
      min="0"
      className="multiplier-input"
    />
    </label>
      </div>
    </div>
    {isLoading ? (
      <p>Loading...</p>
    ) : (
      <table id="ranking-table">
        <thead>
          <tr>
            <th>RANK</th>
            <th>TEAM NAME</th>
            <th>Main Comp Score</th>
            <th>Agent Score</th>
            <th>Total Points</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={index} style={{ backgroundColor: team.color || 'white', color: 'black' }}>
              <td>{index + 1}</td>
              <td>{team.name}</td>
              <td>{team.mainCompScore}</td>
              <td>{team.agentScore}</td>
              <td>{team.totalPoints.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </section>
);
};

export default FinalRankings;
